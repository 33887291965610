import React, { useState, useRef, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import cx from "classnames";
import Icon from "@components/icon";
import { useAnimation, m, useViewportScroll } from "framer-motion";

import { isBrowser } from "@lib/helpers";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
const mobileNavContainerVariants = {
  visible: {
    opacity: 1,
    transition: { duration: 1 },
    pointerEvents: "auto",
    transition: {
      staggerChildren: 0.25,
    },
  },
  hidden: { pointerEvents: "none", opacity: 0 },
};

const Header = ({ page, footerCtaLabel = false }) => {
  // expand our header data
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const { scrollY } = useViewportScroll();
  /** this hook manages state **/
  const [hidden, setHidden] = useState(false);
  function updateNavState() {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
    } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
    }
    if (scrollY?.current <= 100) {
      setHasScrolled(false);
    } else {
      setHasScrolled(true);
    }
  }
  useEffect(() => {
    return scrollY.onChange(() => updateNavState());
  });
  const targetRef = useRef();
  useEffect(
    function () {
      if (mobileNavOpen) {
        disableBodyScroll(targetRef.current);
      } else {
        clearAllBodyScrollLocks();
      }
    },
    [mobileNavOpen, targetRef]
  );
  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
    hidden: {
      opacity: 0,
      y: "-100%",
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
  };
  return (
    <>
      <a href="#content" className="sr-only">
        Skip to Content
      </a>

      <m.header
        variants={variants}
        animate={hidden ? "hidden" : "visible"}
        className={cx(
          "header",
          "px-gutterInset",
          "py-6",
          "fixed",
          "w-full",
          "top-0",
          {
            "has-scrolled": hasScrolled,
            "z-40": !mobileNavOpen,
            "z-[100] is-open is-white-bg": mobileNavOpen,
          }
        )}
      >
        <div className={cx("grid", "grid-cols-4", "md:grid-cols-20")}>
          <div
            className={cx("col-span-2", "md:col-span-10", "relative", "z-50")}
          >
            <h1>
              <Link scroll={false} href="/">
                <a aria-label="Go Home">
                  <span className="sr-only">Dwell House</span>
                  <Icon
                    name="Logo"
                    id="header-logo-icon"
                    viewBox="0 0 124 25"
                    color={"#000000"}
                    className={cx("max-w-[124px]")}
                  />
                </a>
              </Link>
            </h1>
          </div>
          <div
            className={cx(
              "col-span-2",
              "md:col-span-10",
              "flex",
              "md:hidden",
              "justify-end",
              "items-center",
              "relative",
              "z-50"
            )}
          >
            <button
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
              className={cx("w-[32px]", "h-[32px]")}
            >
              {mobileNavOpen && (
                <Icon
                  name="MenuToggleClose"
                  viewBox="0 0 32 32"
                  color={"#000000"}
                />
              )}
              {!mobileNavOpen && (
                <Icon name="MenuToggle" viewBox="0 0 32 32" color={"#000000"} />
              )}
            </button>
          </div>
          <m.div
            initial="hidden"
            animate={mobileNavOpen ? "visible" : "hidden"}
            variants={mobileNavContainerVariants}
            className={cx(
              "col-span-4",
              "flex",
              "fixed",
              "h-[100vh]",
              "w-screen",
              "z-40",
              "top-0",
              "left-0",
              "md:hidden",
              "p-4",
              "overflow-auto",

              "bg-white"
            )}
            ref={targetRef}
          >
            <div
              className={cx(
                "flex",
                "flex-col",
                "text-center",
                "mt-[70px]",
                "w-full"
              )}
            >
              <Link href="/" scroll={false}>
                <a
                  className={cx(
                    "text-subheader-2",
                    "uppercase",
                    "py-8",
                    "border-b",
                    "block"
                  )}
                >
                  Overview
                </a>
              </Link>
              <Link href="/design" scroll={false}>
                <a
                  className={cx(
                    "text-subheader-2",
                    "uppercase",
                    "py-8",
                    "border-b",
                    "block"
                  )}
                >
                  Design
                </a>
              </Link>
              <Link href="/delivery" scroll={false}>
                <a
                  className={cx(
                    "text-subheader-2",
                    "uppercase",
                    "py-8",
                    "border-b",
                    "block"
                  )}
                >
                  Delivery
                </a>
              </Link>
              <Link href="/cost" scroll={false}>
                <a
                  className={cx(
                    "text-subheader-2",
                    "uppercase",
                    "py-8",
                    "border-b",
                    "block"
                  )}
                >
                  Cost
                </a>
              </Link>
              <Link href="/about" scroll={false}>
                <a
                  className={cx(
                    "text-subheader-2",
                    "uppercase",
                    "py-8",
                    "block"
                  )}
                >
                  About
                </a>
              </Link>
            </div>
          </m.div>

          <div
            className={cx(
              "col-span-10",
              "hidden",
              "md:flex",
              "items-center",
              "justify-end"
            )}
          >
            <Link href="/" scroll={false}>
              <a
                className={cx("ml-0", "type--nav", {
                  "border-b": page.title == "Home",
                })}
              >
                Overview
              </a>
            </Link>
            <Link href="/design" scroll={false}>
              <a
                className={cx("ml-10", "type--nav", {
                  "border-b": page.title == "Design",
                })}
              >
                Design
              </a>
            </Link>
            <Link href="/delivery" scroll={false}>
              <a
                className={cx("ml-10", "type--nav", {
                  "border-b": page.title == "Delivery",
                })}
              >
                Delivery
              </a>
            </Link>
            <Link href="/cost" scroll={false}>
              <a
                className={cx("ml-10", "type--nav", {
                  "border-b": page.title == "Cost",
                })}
              >
                Cost
              </a>
            </Link>
            <Link href="/about" scroll={false}>
              <a
                className={cx("ml-10", "type--nav", {
                  "border-b": page.title == "About",
                })}
              >
                About
              </a>
            </Link>
          </div>
        </div>
      </m.header>
      {page.title !== "Contact" && (
        <div
          className={cx(
            "fixed",
            "px-gutterInset",
            "py-[12px]",
            "md:py-[14px]",
            "z-50",
            "w-full",
            "bottom-0",
            "left-0",
            "bg-white",
            "flex",
            "items-center",
            "justify-between"
          )}
        >
          <div
            className={cx(
              "abodu-tag",
              "flex",
              "items-end",
              "leading-1",
              "md:w-3/4",
              "text-[13px]",
              "xs:text-[14px]",
              "md:text-[16px]",
              "tracking-[0.04em]",
              "font-sansRegular"
            )}
          >
            <span className="leading-none inline-block">Exclusively from</span>{" "}
            <a
              href="https://abodu.com/dwellhouse?utm_referrer=dwell.com"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="AboduLogo"
                id="abodu-logo-alt"
                viewBox="0 0 68 16"
                className={cx(
                  "w-[85px]",
                  "h-[20px]",
                  "xs:w-[102px]",
                  "xs:h-[24px]",
                  "ml-1",
                  "xs:ml-2"
                )}
              />
            </a>
          </div>
          <Link href="/order">
            <a
              className={cx(
                "bg-tan",
                "text-[10px]",
                "xxs:text-[14px]",
                "md:text-[15px]",
                "font-sansRegular",
                "font-normal",
                "uppercase",
                "px-3",
                "py-[5px]",
                "whitespace-nowrap",
                "rounded-default",
                "tracking-[0.08em]"
              )}
            >
              {footerCtaLabel ? footerCtaLabel : "Get Yours"}
            </a>
          </Link>
        </div>
      )}
    </>
  );
};

export default Header;
