import React, { useEffect, useState } from "react";
import Script from "next/script";
import { m } from "framer-motion";

import { isBrowser, isMobileSafari, useWindowSize } from "@lib/helpers";
import { useSiteContext } from "@lib/context";
import { pageTransitionSpeed } from "@lib/animate";
import HeadSEO from "@components/head-seo";
import Header from "@components/header";
import Footer from "@components/footer";

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: pageTransitionSpeed / 1000,
      delay: 0.2,
      ease: "linear",
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: pageTransitionSpeed / 1000,
      ease: "linear",
      when: "beforeChildren",
    },
  },
};

const Layout = ({ site = {}, page = {}, schema, children }) => {
  // set window height var (w/ safari/iOS hack)
  const { height: windowHeight } = useWindowSize();
  const [lockHeight, setLockHeight] = useState(false);
  const hasChin = isMobileSafari();
  const { currentSiteTheme } = useSiteContext();
  // set header height
  const [headerHeight, setHeaderHeight] = useState(null);

  useEffect(() => {
    if (isBrowser && !lockHeight) {
      document.body.style.setProperty("--vh", `${windowHeight * 0.01}px`);
      setLockHeight(hasChin);
    }
  }, [windowHeight, hasChin]);

  return (
    <>
      <HeadSEO site={site} page={page} schema={schema} />

      <Script
        async
        id="gtm"
        src="https://www.googletagmanager.com/gtag/js?id=G-NCR1GV98YQ"
      />
      <Script
        id="ga"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-NCR1GV98YQ');`,
        }}
      />

      <m.div
        initial="initial"
        animate="enter"
        exit="exit"
        variants={variants}
        style={headerHeight ? { "--headerHeight": `${headerHeight}px` } : null}
      >
        <div
          className="site-wrap min-h-[calc(100vh-var(--header-height))] flex flex-col"
          data-theme={page.title == "Thank You" ? "yellow" : currentSiteTheme}
        >
          <div className="primary-content bg-gray">
            <Header
              page={page}
              data={site.header}
              footerCtaLabel={site.footerCtaLabel ? site.footerCtaLabel : false}
              onSetup={({ height }) => setHeaderHeight(height)}
            />
            <main id="content">{children}</main>
          </div>
          {page.title !== "Contact" && (
            <Footer data={site.footer} site={site} />
          )}
        </div>
      </m.div>
    </>
  );
};

export default Layout;
