import React from "react";
import dynamic from "next/dynamic";

const Hero = dynamic(() => import("./hero"));
const IntroText = dynamic(() => import("./intro-text"));
const TextAndMedia = dynamic(() => import("./text-and-media"));
const Interactive = dynamic(() => import("./interactive"));
const FeatureCarousel = dynamic(() => import("./feature-carousel"));
const CTA = dynamic(() => import("./cta"));
const ArticleListing = dynamic(() => import("./article-listing"));
const Partners = dynamic(() => import("./partners"));
const DiagramTabs = dynamic(() => import("./diagram-tabs"));
const Grid = dynamic(() => import("./grid"));
const Gallery = dynamic(() => import("./gallery"));
const Expanders = dynamic(() => import("./expanders"));
const ContactForm = dynamic(() => import("./contact-form"));
const Spacer = dynamic(() => import("./spacer"));

export const Module = ({ index, module }) => {
  const type = module._type;
  switch (type) {
    case "hero":
      return <Hero index={index} data={module} />;
    case "introText":
      return <IntroText index={index} data={module} />;
    case "textAndMedia":
      return <TextAndMedia index={index} data={module} />;
    case "cta":
      return <CTA index={index} data={module} />;
    case "contactForm":
      return <ContactForm index={index} data={module} />;
    case "articleListing":
      return <ArticleListing index={index} data={module} />;
    case "expanders":
      return <Expanders index={index} data={module} />;
    case "partners":
      return <Partners index={index} data={module} />;
    case "featureCarousel":
      return <FeatureCarousel index={index} data={module} />;
    case "diagramTabs":
      return <DiagramTabs index={index} data={module} />;
    case "gallery":
      return <Gallery index={index} data={module} />;
    case "grid":
      return <Grid index={index} data={module} />;
    case "interactive":
      return <Interactive index={index} data={module} />;
    case "spacer":
      return <Spacer index={index} data={module} />;
    default:
      return null;
  }
};
