import React from "react";
import Link from "next/link";
import cx from "classnames";
import Icon from "@components/icon";
import CustomLink from "@components/link";

const Footer = ({ site, data = {} }) => {
  return (
    <footer
      className={cx(
        "footer",
        "mt-auto",
        "bg-slate",
        "text-white",
        "px-gutter",
        "md:px-gutterInset",
        "py-20",
        "w-full",
        "relative",
        "z-70"
      )}
      role="contentinfo"
    >
      <div className={cx("grid", "grid-cols-4", "md:grid-cols-20", "w-full")}>
        <div
          className={cx(
            "col-span-20",
            "flex",
            "flex-col",
            "items-center",
            "pb-20"
          )}
        >
          <p className={cx("text-lg-2-mobile", "md:text-lg-2", "text-center")}>
            See if your property qualifies
          </p>
          <div
            className={cx("divider", "h-[100px]", "w-[1px]")}
            style={{
              backgroundColor: "#ffffff",
            }}
          ></div>
          <Link href="/order">
            <a aria-label="Contact" className={cx("btn", "btn-big", "mt-2")}>
              Let&apos;s Go{" "}
              <div className={cx("long-arrow")}>
                <Icon name="LongArrow" color="#000000" viewBox="0 0 47 13" />
              </div>
            </a>
          </Link>
        </div>
      </div>
      <div
        className={cx(
          "grid",
          "grid-cols-4",
          "md:grid-cols-20",
          "w-full",
          "items-center"
        )}
      >
        <div
          className={cx(
            "col-span-2",
            "md:col-span-10",
            "flex",
            "justify-start",

            "mb-0"
          )}
        >
          <Link href="/">
            <a aria-label="Go Home" className={cx("block")}>
              <span className="sr-only">Dwell House</span>
              <Icon
                name="Logo"
                id="header"
                color="#ffffff"
                viewBox="0 0 124 25"
                className={cx("w-[110px] md:w-[154px]")}
              />
            </a>
          </Link>
        </div>
        <div
          className={cx("col-span-2", "md:col-span-10", "flex", "justify-end")}
        >
          <div
            className={cx(
              "abodu-tag",
              "flex",
              "items-bottom",

              "text-[13px]",
              "tracking-[0.04em]",
              "font-sansRegular",
              "whitespace-nowrap"
            )}
          >
            Exclusively from{" "}
            <a
              href="https://abodu.com/dwellhouse?utm_referrer=dwell.com"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="AboduLogo"
                id="abodu-logo-footer"
                viewBox="0 0 68 16"
                color="#ffffff"
                className={cx("w-[68px]", "h-[16px]", "ml-2", "mt-[-1px]")}
              />
            </a>
          </div>
        </div>
      </div>
      <div
        className={cx(
          "grid",
          "grid-cols-4",
          "md:grid-cols-20",
          "w-full",
          "items-center"
        )}
      >
        <div
          className={cx(
            "col-span-4",
            "md:col-span-20",
            "flex",
            "flex-col",
            "md:flex-row",
            "items-center",
            "justify-center",
            "mt-20",
            "mb-0",
            "text-sm"
          )}
        >
          {site?.footerText && (
            <span className="block md:inline">{site.footerText}</span>
          )}
          {site?.footerLinks && (
            <div className="block md:inline mt-2 md:mt-0">
              {site.footerLinks.map((link, idx) => (
                <span key={idx} className="inline">
                  <CustomLink className="small-link mt-5 mx-2" link={link} />
                  {site.footerLinks.length - 1 > idx && <span>|</span>}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
